import { isSessionStorage } from "../Lib/utils";
import {
  CategoryHelperObject,
  ElementInterface,
  homePageHelperObject,
} from "./googleAnalytics.interface";

export const dataLayer: ElementInterface[] = [];

// const format: ElementInterface = {
//   event: "<event>",
//   category: "<category>",
//   action: "<action>",
//   label: "<label>",
//   page: "<page>
//   title: "<title>",
//   cd2: "<<pass encrypted customer id>>",
//   cd24: "<<pass platform either website or internet-banking or mobile-app>>",
// };

export function excecuteGA(value: ElementInterface): void {
  dataLayer.push(value);
}

export const handleGAPush = ({
  category,
  action,
  label,
  page,
  title,
  event,
  cd2,
  cd24,
  user_id,
}: ElementInterface): void => {
  const channelId = isSessionStorage("channelId")
    ? sessionStorage.getItem("channelId")
    : "";
  let platform =
    channelId === "IB"
      ? "internet-banking"
      : channelId === "MB"
      ? " mobile-app"
      : "website";

  //Start GA push
  const gaObject: ElementInterface = {
    event: event.toLowerCase(),
    category: category.toLowerCase(),
    action: CategoryHelperObject.action + action.toLowerCase(),
    label: label.toLowerCase(),
    cd2: cd2,
    cd24: cd24,
    page,
    title,
    user_id: user_id,
    platform: platform,
  };
  // @ts-expect-error explanation: window datalayer will be present Remove double slash from @ sign once GA needs to be activated
  // console.log(gaObject); // remove this once GA needs to be activated
  window.dataLayer.push(gaObject); // uncomment this once GA needs to be activated
  // dataLayer.push(gaObject);
  //end GA push
};
