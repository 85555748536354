export const HOME: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "landing page",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/landing-page",
    title: "my money - landing page",
  },
  INVESTMENT_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your investments",
    label: "view details",
  },
  INVESTMENT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your investments",
    label: "",
  },
  LESS_INVESTMENT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your investments",
    label: "less",
  },
  MORE_INVESTMENT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your investments",
    label: "more",
  },
  SPENDS_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your spends",
    label: "view details",
  },
  SPENDS_LAST6_MONTHS: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your spends",
    label: "track last 6 months spends",
  },
  SPENDS_CATEGORY_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your spends",
    label: "<<spend category>>",
  },
  SPENDS_TRANSACTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your spends",
    label: "<<spend transaction>>",
  },
  CREDIT_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - credit score",
    label: "view details",
  },
  CHECK_PROTECTION_COVER: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your protection cover",
    label: "check your protection cover",
  },
  NOTIFICATION_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - notification",
    label: "notification icon",
  },
  BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "",
    label: "back icon",
  },
  CASHFLOW_MONTH_DROPDOWN_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly cashflow",
    label: "month dropdown",
  },
  CASHFLOW_MONTH_DROPDOWN_MONTH_SELECT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly cashflow",
    label: "<<selected month>>",
  },
  CASHFLOW_INCOME_ADD_BUTON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly cashflow – Income",
    label: "Add",
  },
  CASHFLOW_CHART_INVESTMENTS_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly cashflow – Investment",
    label: "Investment",
  },
  TOTAL_INVESTMENT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page",
    label: "Total Investments",
  },
};

export const PROTECTION: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your protection cover",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/your-protection cover",
    title: "my money - your protection cover",
  },

  LIFE_INSURANCE_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your protection cover",
    label: "apply for life insurance",
  },
  HEALTH_INSURANCE_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your protection cover",
    label: "apply for health insurance",
  },
  LIFE_INSURANCE_INFOICON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your protection cover",
    label: "info icon - apply for life insurance",
  },
  HEALTH_INSURANCE_INFOICON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your protection cover",
    label: "info icon - apply for health insurance",
  },
  BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "your protection cover",
    label: "back icon",
  },
};

export const NOTIFICATION: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your notifications",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/your-notifications",
    title: "my money - your notifications",
  },
  FILTER_OPTION_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your notifications",
    label: "",
  },
  CARD_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your notifications",
    label: "",
  },
  CTA_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your notifications",
    label: "",
  },
  BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "your notifications",
    label: "back icon",
  },
};

export const INVESTMENT: any = {
  WITHOUT_INVESTMENTS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "investment module - without investments",
  },
  WITHOUT_INVESTMENTS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/investment-module-without-investments",
    title: "my money - investment module - without investments",
  },
  WITHOUT_INV_START_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "start now - *",
  },
  WITHOUT_INV_INVEST_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "invest now - *",
  },
  WITHOUT_INV_KNOW_MORE: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "know more",
  },
  WITHOUT_INV_OPEN_DEMAT: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "open demat - stocks",
  },
  WITHOUT_INV_BANNER_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "<<banner text>> - <<position>>",
  },
  WITHOUT_INVESTMENTS_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - without investments",
    label: "back icon",
  },
  WITH_INVESTMENTS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "investment module - with some investments",
  },
  WITH_INVESTMENTS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/investment-module-with-some-investments",
    title: "my money - investment module - with some investments",
  },
  WITH_INVESTMENTS_DROPDOWN_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "drop down - *",
  },
  WITH_INVESTMENTS__BANNER_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "<<banner text>> - <<position>>",
  },
  WITH_INVESTMENTS_INVEST_MORE: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "invest more - *",
  },
  WITH_INVESTMENTS_INVEST_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "invest now - *",
  },
  WITH_INVESTMENTS_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "view details - *",
  },
  WITH_INVESTMENTS_START_NOW_CTA: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "start now - *",
  },
  WITH_INVESTMENTS_START_SIP_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "start sip now - *",
  },
  WITH_INVESTMENTS_REACTIVATE_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "reactivate now - stocks",
  },
  WITH_INVESTMENTS_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "investment module - with some investments",
    label: "back icon",
  },
};

export const CREDIT_PAGE: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "credit score",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/credit-score",
    title: "my money - credit score",
  },
  DOWNLOAD_REPORT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score",
    label: "download report",
  },
  CREDIT_SCORE_OPTION_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score",
    label: "",
  },
  PAY_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score",
    label: "pay now - payment due",
  },
  BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score",
    label: "back icon",
  },
  EXPLORE_LOANS: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score - secured vs unsecured loan",
    label: "explore loans",
  },
  DOT_ICON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score - secured vs unsecured loan",
    label: "three dot icon",
  },
  VIEW_CREDIT_REPORT: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit score",
    label: "view your credit report",
  },
  SECURED_UNSECURED_OPEN: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "secured vs unsecured loans",
  },
  SECURED_UNSECURED_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/secured-vs-unsecured-loans",
    title: "my money - secured vs unsecured loans",
  },
  LOAN_CLICK_SECURED_UNSECURED: {
    event: "my_money_vpv",
    category: "",
    action: "secured vs unsecured loans",
    label: "<<loan>> - <<type of loan>>",
  },
  CREDIT_LINE_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "credit line",
  },
  CREDIT_LINE_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/credit-line",
    title: "my money - credit line",
  },
  CREIDT_LINE_LOAN_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit line",
    label: "<<bank>> - <<type of loan>>",
  },
  CREDIT_LINE_CREDITCARD_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit line",
    label: "<<bank>> - <<card>>",
  },
  SHOW_MORE_LOAN_OR_CREDITCARD: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit line",
    label: "show more - *",
  },
  CREDIT_LINE_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit line",
    label: "back icon",
  },
  CREDIT_CARD_CREDITLINE_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "credit card - credit line",
  },
  CREDIT_CARD_CREDITLINE_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/credit-card-credit-line",
    title: "my money - credit card - credit line",
  },
  CREIDT_CARD_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit card - credit line",
    label: "view details",
  },
  CREDIT_CARD_ACCESS_YOUR_PROFILE: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit card - credit line",
    label: "access your profile",
  },
  CREDIT_CARD_MONTH_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "<<month>> - month selection",
    label: "reactivate now - stocks",
  },
  CREDIT_CARD_INFO_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit card - credit line",
    label: "info icon",
  },
  CREDIT_LINE_CARD_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit card - credit line",
    label: "back icon",
  },
  LOAN_CREDITLINE_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "loan - credit line",
  },
  LOAN_CREDITLINE_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/loan-credit-line",
    title: "my money - loan - credit line",
  },
  LOAN_CREDITLINE_APPLY_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "loan - credit line",
    label: "apply now",
  },
  LOAN_ACCESS_YOUR_PROFILE: {
    event: "my_money_onclick",
    category: "my money",
    action: "loan - credit line",
    label: "access your profile",
  },
  LOAN_MONTH_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "loan - credit line",
    label: "<<month>> - month selection",
  },
  CREDIT_LINE_LOAN_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "loan - credit line",
    label: "back icon",
  },
  DISCLAIMER_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "credit information - disclaimer",
  },
  DISCLAIMER_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/credit-information-disclaimer",
    title: "my money - credit information - disclaimer",
  },
  DISCLAIMER_ACCEPT: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit information - disclaimer",
    label: "accept",
  },
  DISCLAIMER_CANCEL: {
    event: "my_money_onclick",
    category: "my money",
    action: "credit information - disclaimer",
    label: "cancel",
  },
};

export const SPENDS_PAGE: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your monthly spends",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/your-monthly-spends",
    title: "my money - your monthly spends",
  },
  LAST_6MONTHS_OPTION: {
    event: "my_money_onclick",
    category: "my money",
    action: "your spends",
    label: "<<options>>",
  },
  SPENDS_CATEGORY_SELECT_FILTER: {
    event: "my_money_onclick",
    category: "my money",
    action: "your spends",
    label: "<<spend category>>",
  },
  SPENDS_MONTHS_ARROW_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "right arrow/left arrow",
  },
  SPENDS_CATEGORY_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "<<spend category>>",
  },
  SPENDS_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "back icon",
  },
  YOUR_MONTHLY_SPENDS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your monthly spends",
  },
  YOUR_MONTHLY_SPENDS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/your-monthly-spends",
    title: "my money - your monthly spends",
  },
  YOUR_MONTHLY_SPENDS_CATEGORY_DROPDOWN: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "spend category drop down",
  },
  YOUR_MONTHLY_SPENDS_MONTH_ARROW: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "right arrow/left arrow",
  },
  YOUR_MONTHLY_SPENDS_CATEGORY_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "<<spend category>",
  },
  YOUR_MONTHLY_SPENDS_BACK_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "back icon",
  },
  SORT_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "sort",
  },
  FILTER_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "filter",
  },
  SORT_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "sort - your monthly spends",
  },
  SORT_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/sort-your-monthly-spends",
    title: "my money - sort - your monthly spends",
  },
  SORT_DROPDOWN_CATEGORY_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "sort - your monthly spends",
    label: "spend category drop down",
  },
  SORT_OPTION_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "sort - your monthly spends",
    label: "<<sort options>>",
  },
  CLOSE_SORT: {
    event: "my_money_onclick",
    category: "my money",
    action: "sort - your monthly spends",
    label: "close icon",
  },
  SPENDS_FILTER_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "filter - your monthly spends",
  },
  SPENDS_FILTER_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/filter-your-monthly-spends",
    title: "my money - filter - your monthly spends",
  },
  CLEAR_ALL_FILTER: {
    event: "my_money_onclick",
    category: "my money",
    action: "filter - your monthly spends",
    label: "close all",
  },
  APPLY_FILTER: {
    event: "my_money_onclick",
    category: "my money",
    action: "filter - your monthly spends",
    label: "apply",
  },
  CANCEL_FILTER: {
    event: "my_money_onclick",
    category: "my money",
    action: "filter - your monthly spends",
    label: "cancel",
  },
  SELECT_FILTER: {
    event: "my_money_onclick",
    category: "my money",
    action: "filter - your monthly spends",
    label: "<<filter options>>",
  },
  CLOSE_FILTER_ICON: {
    event: "my_money_onclick",
    category: "my money",
    action: "filter - your monthly spends",
    label: "close icon",
  },
  SUB_CATEGORY_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "sub category - your monthly spends",
  },
  SUB_CATEGORY_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/sub-category-your-monthly-spends",
    title: "my money - subcategory - your monthly spends",
  },
  SUB_CATEGORY_SELECT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly spends",
    label: "<<selected sub category>>",
    page: "",
    title: "",
  },
};

export const ERROR_PAGE: any = {
  INVESTMENT_DETAILS_ERROR_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "difficulties in fetching investment details",
  },
  INVESTMENT_DETAILS_ERROR_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/difficulties-in-fetching-investment-details",
    title: "my money - difficulties in fetching investment details",
  },
  INVESTMENT_DETAILS_ERROR_OKAY: {
    event: "my_money_onclick",
    category: "my money",
    action: "difficulties in fetching investment details",
    label: "okay",
  },
  DIFFICULTY_FETCHING_DETAILS_ERROR_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "difficulties in fetching your details",
  },
  DIFFICULTY_FETCHING_DETAILS_ERROR_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/difficulties-in-fetching-your-details",
    title: "my money - difficulties in fetching your details",
  },
  DIFFICULTY_FETCHING_DETAILS_ERROR_OKAY: {
    event: "my_money_onclick",
    category: "my money",
    action: "difficulties in fetching your details",
    label: "okay",
  },
  DIFFICULTY_PROCESSING_REQUEST_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "difficulties in processing your details",
  },
  DIFFICULTY_PROCESSING_REQUEST_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/difficulties-in-processing-your-details",
    title: "my money - difficulties in processing your details",
  },
  DIFFICULTY_PROCESSING_REQUEST_OKAY: {
    event: "my_money_onclick",
    category: "my money",
    action: "difficulties in processing your details",
    label: "okay",
  },
  SORRY_FOR_INCOVINIENCE_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "sorry for the inconvenience",
  },
  SORRY_FOR_INCOVINIENCE_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/sorry-for-the-inconvenience",
    title: "my money - sorry for the inconvenience",
  },
  SORRY_FOR_INCOVINIENCE_PAGE_ONLOAD_TRY_AGAIN: {
    event: "my_money_onclick",
    category: "my money",
    action: "sorry for the inconvenience",
    label: "try again",
  },
  NO_INTERNET_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "no internet connection",
  },
  NO_INTERNET_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/no-internet-connection",
    title: "my money - no internet connection",
  },
  NO_INTERNET_PAGE_TRY_AGAIN: {
    event: "my_money_onclick",
    category: "my money",
    action: "no internet connection",
    label: "try again",
  },
  TIMEOUT_PAGE_ONLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "time out - fetching details",
  },
  TIMEOUT_PAGE_ONLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/time-out-fetching-details",
    title: "my money - time out - fetching details",
  },
  TIMEOUT_PAGE_TRY_AGAIN: {
    event: "my_money_onclick",
    category: "my money",
    action: "time out - fetching detailsn",
    label: "try again",
  },
};

export const NETWORTH: any = {
  TRACK_NETWORTH: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your bank accounts",
    label: "track your networth",
  },
  HIDDEN_EYE: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page - your bank accounts",
    label: "eye mask",
  },
  TOTAL_NETWORTH_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "total networth",
  },
  TOTAL_NETWORTH_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/total-networth",
    title: "my money - total networth",
  },
  TOTAL_NETWORTH_CLOSE: {
    event: "my_money_onclick",
    category: "my money",
    action: "total networth",
    label: "close icon",
  },
  TOTAL_NETWORTH_GOT_IT: {
    event: "my_money_onclick",
    category: "my money",
    action: "total networth",
    label: "got it",
  },
  TOTAL_NETWORTH_DROPDOWN: {
    event: "my_money_onclick",
    category: "my money",
    action: "total networth",
    label: "dropdown - text",
  },
  NETWORTH_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth",
  },
  NETWORTH_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/my-networth",
    title: "my money - your networth",
  },
  MY_NETWORTH_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth",
    label: "back icon",
  },
  MY_NETWORTH_ASSETS: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth",
    label: "assets",
  },
  MY_NETWORTH_LIABILITIES: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth",
    label: "liabilities",
  },
  MY_NETWORTH_BANK_ACCOUNTS_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your bank accounts",
    label: "view details",
  },
  MY_NETWORTH_BANK_ACCOUNTS_SELECT_ACCOUNT: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your bank accounts",
    label: "Bank account selection - Name",
  },
  MY_NETWORTH_BANK_ACCOUNTS_LINK_OTHER: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your bank accounts",
    label: "link other bank accounts",
  },
  MY_NETWORTH_BANK_ACCOUNTS_INVEST_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your bank accounts",
    label: "invest now - pfm",
  },
  MY_NETWORTH_INVESTMENTS_VIEW_DETAILS: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your investments",
    label: "view details",
  },
  MY_NETWORTH_INVESTMENTS_MODULES: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your investments",
    label: "<<invest modules>>",
  },
  MY_NETWORTH_INVESTMENTS_ADD_MODULE: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your investments",
    label: "add icon - <<invest modules>>",
  },
  MY_NETWORTH_INVESTMENTS_MORE: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your investments",
    label: "more",
  },
  MY_NETWORTH_INVESTMENTS_LESS: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your investments",
    label: "less",
  },
  MY_NETWORTH_OTHER_ASSETS: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - other assets",
    label: "<<other assets>>",
  },
  MY_NETWORTH_OTHER_ASSETS_ADD: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - other assets",
    label: "add other assets",
  },
  TOTAL_BANK_BALANCE_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "total bank balance",
  },
  TOTAL_BANK_BALANCE_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: 'vpv/my-money/my-networth/total-bank-balance',
    label: "total bank balance",
  },
  TOTAL_BANK_BALANCE_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "total bank balance",
    label: "back icon",
  },
  TOTAL_BANK_BALANCE_GOT_IT: {
    event: "my_money_onclick",
    category: "my money",
    action: "total bank balance",
    label: "got it",
  },
  YOUR_INVESTMENT_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your investments",
  },
  YOUR_INVESTMENT_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: 'vpv/my-money/my-networth/your-investments',
    label: "your investments",
  },
  YOUR_INVESTMENT_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investments",
    label: "back icon",
  },
  YOUR_INVESTMENT_GOT_IT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investments",
    label: "got it",
  },
  OTHER_LIABILITIES_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "other liabalities",
  },
  OTHER_LIABILITIES_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: 'vpv/my-money/my-networth/other-liabalities',
    label: "other liabalitiess",
  },
  OTHER_LIABILITIES_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "other liabalities",
    label: "back icon",
  },
  ADD_OTHER_LIABILITIES: {
    event: "my_money_onclick",
    category: "my money",
    action: "other liabalities",
    label: "add liabilities",
  },
  UNABLE_TO_FETCH_INVESTMENT_DETAILS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "unable to fetch investment details",
  },
  UNABLE_TO_FETCH_INVESTMENT_DETAILS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    PAGE: "vpv/my-money/my-networth/unable-to-fetch-investment-details",
    label: "",
  },
  UNABLE_TO_FETCH_INVESTMENT_DETAILS_CLOSE: {
    event: "my_money_onclick",
    category: "my money",
    action: "unable to fetch investment details",
    label: "close icon",
  },
  UNABLE_TO_FETCH_INVESTMENT_DETAILS_OKAY: {
    event: "my_money_onclick",
    category: "my money",
    action: "unable to fetch investment details",
    label: "Okay",
  },
  ADD_ASSETS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - add assets",
  },
  ADD_ASSETS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/add-assets",
    label: "",
  },
  ADD_ASSETS_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - add assets",
    label: "back icon",
  },
  ADD_ASSETS_ADD: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - add assets",
    label: "add",
  },
  EDIT_ASSETS_PAGLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - edit assets",
  },
  EDIT_ASSETS_PAGLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/edit-assets",
    label: "",
  },
  EDIT_ASSETS_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - edit assets",
    label: "back icon",
  },
  EDIT_ASSETS_EDIT: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - edit assets",
    label: "edit",
  },
  DELETE_ASSETS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - delete assets",
  },
  DELETE_ASSETS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/delete-assets",
    label: "",
  },
  DELETE_ASSETS_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete assets",
    label: "back icon",
  },
  DELETE_ASSETS_NO: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete assets",
    label: "no, go back",
  },
  DELETE_ASSETS_YES: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete assets",
    label: "yes, delete",
  },
  YOUR_LIABILITIES_ONCLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - your liabilities",
    label: "<<liability>>",
  },
  OTHER_LIABILITIES_ONCLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - other liabilities",
    label: "<<liability>>",
  },
  OTHER_LIABILITIES_ADD: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - other liabilities",
    label: "add other liabilities",
  },
  EXTERNAL_LIABILITIES_ONCLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - external liabilities",
    label: "<<liability>>",
  },
  EXTERNAL_LIABILITIES_TRACK_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - external liabilities",
    label: "track now",
  },
  EXTERNAL_LIABILITIES_REFRESH_CONSENT: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - external liabilities",
    label: "refresh consent",
  },
  NETWORTH_APPLY_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth",
    label: "apply now",
  },
  NETWORTH_EXPLORE_NOW: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth",
    label: "explore now",
  },
  YOUR_LIABILITIES_SPECIFIC_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your liabilities - type",
  },
  YOUR_LIABILITIES_SPECIFIC_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/your-liabilities - type",
    label: "",
  },
  YOUR_LIABILITIES_SPECIFIC_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your liabilities - <<credit card/personal loan/home loan>>",
    label: "back icon",
  },
  YOUR_LIABILITIES_SPECIFIC_GOT_IT: {
    event: "my_money_onclick",
    category: "my money",
    action: "your liabilities - <<credit card/personal loan/home loan>>",
    label: "got it",
  },
  UNABLE_TO_FETCH_LIABILITY_DETAILS_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "unable to fetch liability details",
  },
  UNABLE_TO_FETCH_LIABILITY_DETAILS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/unable-to-fetch-liability-details",
    label: "",
  },
  UNABLE_TO_FETCH_LIABILITY_DETAILS_CLOSE: {
    event: "my_money_onclick",
    category: "my money",
    action: "unable to fetch liability details",
    label: "close icon",
  },
  UNABLE_TO_FETCH_LIABILITY_DETAILS_OKAY: {
    event: "my_money_onclick",
    category: "my money",
    action: "unable to fetch liability details",
    label: "Okay",
  },
  EXTERNAL_LIABILITIES_SPECIFIC_PAGLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "external liabilities -TYPE",
  },
  EXTERNAL_LIABILITIES__SPECIFIC_PAGLOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "external liabilities - TYPE",
  },
  EXTERNAL_LIABILITIES_SPECIFIC_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "external liabilities - <<credit card/personal loan/home loan>>",
    label: "back icon",
  },
  EXTERNAL_LIABILITIES_SPECIFIC_GOTIT: {
    event: "my_money_onclick",
    category: "my money",
    action: "external liabilities - <<credit card/personal loan/home loan>>",
    label: "got it",
  },
  EXTERNAL_LIABILITIES_DISCLAIMER_PAGLOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "external liabilities - disclaimer",
  },
  EXTERNAL_LIABILITIES_DISCLAIMER_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/external-liabilities-disclaimer",
    label: "",
  },
  EXTERNAL_LIABILITIES_DISCLAIMER_ACCEPT: {
    event: "my_money_onclick",
    category: "my money",
    action: "external liabilities - disclaimer",
    label: "accept",
  },
  EXTERNAL_LIABILITIES_DISCLAIMER_CANCEL: {
    event: "my_money_onclick",
    category: "my money",
    action: "external liabilities - disclaimer",
    label: "cancel",
  },
  ADD_LIABILITIES_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - add liabilities",
  },
  ADD_LIABILITIES_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    page: "vpv/my-money/my-networth/add-liabilities",
    label: "",
  },
  ADD_LIABILITIES_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - add liabilities",
    label: "back icon",
  },
  ADD_LIABILITIES_ADD: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - add liabilities",
    label: "add",
  },
  EDIT_LIABILITIES_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - edit liabilities",
  },
  EDIT_LIABILITIES_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    PAGE: "vpv/my-money/my-networth/edit-liabilities",
    label: "",
  },
  EDIT_LIABILITIES_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - edit liabilities",
    label: "back icon",
  },
  EDIT_LIABILITIES_EDIT: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - edit liabilities",
    label: "edit",
  },
  DELETE_LIABILITIES: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete liabilities",
    label: "",
  },
  DELETE_LIABILITIES_PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "my networth - delete liabilities",
  },
  DELETE_LIABILITIES_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    PAGE: "vpv/my-money/my-networth/delete-liabilities",
    label: "",
  },
  DELETE_LIABILITIES_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete liabilities",
    label: "back icon",
  },
  DELETE_LIABILITIES_NO: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete liabilities",
    label: "no,go back",
  },
  DELETE_LIABILITIES_YES: {
    event: "my_money_onclick",
    category: "my money",
    action: "my networth - delete liabilities",
    label: "yes, delete",
  },
};

export const INCOME: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "monthly income",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/landing-page/monthly-income",
    title: "my money – monthly income",
  },
  SAVE_BUTTON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly income",
    label: "Save",
  },
  ONETIME_INCOME_ADD_BUTTON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly income",
    label: "Add",
  },
  INCOME_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly income",
    label: "back",
  },
  ZERO_INCOME_DRAWER_CANCEL_BUTTON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly income",
    label: "cancel",
  },
  ZERO_INCOME_DRAWER_SAVE_BUTTON_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly income",
    label: "<<CTA text>>",
  },
}

export const MONTHLY_INVESTMENTS_SUBCATEGORY: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your investment - ",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/landing-page/monthly-income",
    title: "my money – monthly income",
  },
  SORT_OPTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "sort",
  },
  FILTER_OPTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "filter",
  },
  CHECKBOX_FILTER_OPTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "<<selected filter>>",
  },
  BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "back",
  },
  APPLY_FILTER_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "apply",
  },
  CLOSE_FILTER_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "<<CTA text>>",
  },
  CHECKBOX_SORTED_OPTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your investment - ",
    label: "<<selected sort option>>",
  },
  MONTHLY_INVESTMENTS_PAGE_LOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your monthly investment",
  },
  MONTHLY_INVESTMENTS_PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/landing-page/your-monthly-investment",
    title: "my money – your monthly investment",
  },
  MONTHLY_INVESTMENTS_PAGE_BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your monthly investment",
    label: "back",
  },
}

export const SPENDS_CASHFLOW: any = {
  PAGELOAD: {
    event: "my_money_onload",
    category: "my money",
    action: "pageload",
    label: "your investment – your spends",
  },
  PAGELOAD_VPV: {
    event: "my_money_vpv",
    category: "",
    action: "",
    label: "",
    page: "vpv/my-money/landing-page/your-spends",
    title: "my money – your spends",
  },
  SPENDS_CTA_CASHFLOW_LANDING_PAGE: {
    event: "my_money_onclick",
    category: "my money",
    label: "",
    action: "landing page – Monthly cashflow – Spends",
  },
  BACK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your spends",
    label: "back",
  },
  CREDIT_CARD_OR_SAVINGS_ACC_OR_ALL_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your spends ",
    label: "<<selected option>>",
  },
  TRENDS_OR_CATEGORIES_OPTION_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "your spends ",
    label: "<<selected option>>",
  },
  CREDIT_CARD_EXCLUSION_INFO_CLICK: {
    event: "my_money_onclick",
    category: "my money",
    action: "landing page – Monthly cashflow – Spends ",
    label: "Click here to view",
  }
}

